<template>
  <div class="page">
    <div class="menu-content position-fixed">
      <div
        class="menu-content-items"
        :class="{ active: openListChapter || openNote }"
      >
        <div
          class="menu-content-item"
          @click="
            $router.push({
              name: 'default content',
              params: { id: idContent },
            })
          "
        >
          <div><CIcon name="cil-book" /></div>
          <div>ライブラリ</div>
        </div>
        <div class="menu-content-group-item">
          <div class="menu-content-item" @click="toggleListChapter()">
            <div>
              <div><CIcon name="cil-list" /></div>
            </div>
            <div>もくじ</div>
          </div>
          <div class="menu-content-item border-if" @click="toggleNote()">
            <div>
              <div>
                <div><CIcon name="cil-note-add" /></div>
              </div>
            </div>
            <div>ノート</div>
          </div>
        </div>
        <div class="menu-content-group-item">
          <div
            class="menu-content-item"
            :class="{ disable: indexPage === contentPage.length - 1 }"
            @click="nextPage(indexPage, contentPage.length - 1)"
          >
            <div>
              <div><CIcon name="cil-chevron-right" /></div>
            </div>
            <div>前へ</div>
          </div>
          <div
            class="menu-content-item borderIf"
            :class="{ disable: indexPage === 0 }"
            @click="prevPage(indexPage)"
          >
            <div>
              <div>
                <div><CIcon name="cil-chevron-left" /></div>
              </div>
            </div>
            <div>次へ</div>
          </div>
        </div>
      </div>
      <div class="menu-toggle" :class="{ active: openListChapter }">
        <div class="menu-title">もくじ</div>
        <div>
          <div class="list-chapter" v-if="contentPage.length">
            <div
              class="item-chapter p-3"
              :class="{ borderIf: index !== 0, active: idPage == item.id }"
              v-for="(item, index) in contentPage"
              :key="index"
              @click="pushPage(idContent, item.id)"
            >
              <font-awesome-icon
                class="custom-icon mr-2"
                :icon="['fas', 'check-circle']"
              />
              <div class="chapter-name">{{ item.page_content }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="menu-toggle" :class="{ active: openNote }">
        <div class="menu-toggle-header">
          <div class="header-title-custom px-3">ノート</div>
          <div class="header-btn-custom px-3" v-b-modal.modal-create-note>
            追加する<CIcon class="ml-2 icon-custom" name="cil-note-add" />
          </div>
        </div>
        <div class="list-note">
          <div
            v-for="(value, index) in listdataNote"
            :key="index"
            class="note-item d-flex flex-column"
          >
            <div class="time-note">
              {{
                new Date(value.created_at).toLocaleString("ja-JP", {
                  timeZone: "Asia/Tokyo",
                })
              }}
            </div>
            <div class="title-note my-3">{{ value.title_note }}</div>
            <div class="content-note">{{ value.content_note }}</div>
            <div class="item-note d-flex">
              <div
                class="edit-note mx-2"
                @click="getNoteByIdUser(value.id)"
                v-b-modal.modal-edit-note
              >
                <font-awesome-icon
                  class="custom-icon mr-2"
                  :icon="['fas', 'pencil-alt']"
                />
              </div>
              <div
                class="delete-note mx-2"
                v-on:click="showConfirm(value.id)"
                v-b-modal.modal-delete-note
              >
                <font-awesome-icon
                  class="custom-icon mr-2"
                  :icon="['fas', 'trash']"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="py-5">
        <div v-if="contentPage.length">
          <div
            class="content-page"
            v-for="(item, index) in contentPage"
            :key="index"
          >
            <div class="page-content" v-html="item.page_content"></div>
          </div>
        </div>
        <div v-if="contentChapter.length">
          <div
            class="content-chapter"
            v-for="(item, index) in contentChapter"
            :key="index"
          >
            <div class="page-title h5">{{ item.title }}</div>
            <div class="page-content" v-html="item.chapter_content"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Create Note -->
    <b-modal id="modal-create-note" size="xl" title="Create Note" hide-footer>
      <CRow
        ><CCol sm="12">
          <CInput
            label="Title Note"
            placeholder="Title Note"
            v-model="title_note"
          /> </CCol
        ><CCol sm="12">
          <CInput
            label="Content Note"
            placeholder="Content Note"
            v-model="content_note"
          /> </CCol
        ><CCol sm="12">
          <div class="d-flex flex-column">
            <label>Add Slag</label>
            <div class="custom-select-2">
              <multiselect
                v-model="slag_id"
                tag-placeholder="Add this as new Slag"
                placeholder="Search or add a Slag"
                label="slag_name_en"
                track-by="slag_name_en"
                :options="listSlagOptions"
                :multiple="true"
                :taggable="false"
                :showNoResults="false"
                @tag="addTag"
              ></multiselect>
            </div>
          </div> </CCol
        ><CCol sm="12">
          <div class="d-flex justify-content-center my-2">
            <b-button
              v-on:click.prevent="CreateNewNote()"
              :disabled="isLoading"
              variant="info"
              class="mr-4"
              >Create Note</b-button
            >
            <b-button
              variant="secondary"
              @click="$bvModal.hide('modal-create-note')"
              class="mx-2"
              >Close</b-button
            >
          </div>
        </CCol></CRow
      >
    </b-modal>
    <!-- Modal Create Note -->
    <!-- Modal Edit Note -->
    <b-modal id="modal-edit-note" size="xl" title="Edit Note" hide-footer>
      <CRow
        ><CCol sm="12">
          <CInput
            label="Title Note"
            placeholder="Title Note"
            v-model="noteById.title_note"
          /> </CCol
        ><CCol sm="12">
          <CInput
            label="Content Note"
            placeholder="Content Note"
            v-model="noteById.content_note"
          /> </CCol
        ><CCol sm="12">
          <div class="d-flex flex-column">
            <label>Add Slag</label>
            <div class="custom-select-2">
              <multiselect
                v-model="noteById.slag"
                tag-placeholder="Add this as new Slag"
                placeholder="Search or add a Slag"
                label="slag_name_en"
                track-by="slag_name_en"
                :options="listSlagOptions"
                :multiple="true"
                :taggable="false"
                :showNoResults="false"
                @tag="addTag"
              ></multiselect>
            </div>
          </div> </CCol
        ><CCol sm="12">
          <div class="d-flex justify-content-center my-2">
            <b-button
              v-on:click.prevent="editNote()"
              :disabled="isLoading"
              variant="info"
              class="mr-4"
              >Edit Note</b-button
            >
            <b-button
              variant="secondary"
              @click="$bvModal.hide('modal-edit-note')"
              class="mx-2"
              >Close</b-button
            >
          </div>
        </CCol></CRow
      >
    </b-modal>
    <!-- Modal Edit Note -->
    <!-- Modal Delete Note -->
    <b-modal id="modal-delete-note" hide-header hide-footer>
      <div class="d-block text-center">
        <h5>
          削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
        </h5>
      </div>
      <div class="d-flex justify-content-center">
        <b-button
          class="mx-2 mt-3 btn btn-danger"
          block
          @click="removeNote(dataModal)"
          >Delete</b-button
        >
        <b-button
          class="mx-2 mt-3 btn btn-secondary"
          block
          @click="$bvModal.hide('modal-delete-note')"
        >
          Cancel
        </b-button>
      </div>
    </b-modal>
    <!-- Modal Delete Note -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Page",
  data() {
    return {
      openListChapter: false,
      openNote: false,
      idPage: "",
      idContent: "",
      indexPage: "",
      listdataNote: [],
      title_note: "",
      content_note: "",
      content_id: null,
      page_id: null,
      page_pdf: null,
      seconds_watch_video: null,
      slag_id: [],
      listSlagOptions: [],
      isLoading: false,
      slag_note: [],
      optionSlag: [],
      dataModal: "",
    };
  },
  created() {
    let { idContent, idPage } = this.$route.params;
    this.getContentChapter(idPage);
    this.getContentPage(idContent);
    this.getListNote(idContent);
    this.idPage = idPage;
    this.idContent = idContent;
    this.getListSlag();
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  watch: {
    contentPage() {
      let { contentPage, getIndex } = this;
      contentPage.forEach(getIndex);
    },
    listNote() {
      this.listdataNote = [];
      this.listdataNote = this.listNote;
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        id: item.id,
        slag_name_en: item.slag_name_en,
      }));
    },
    slag_id() {
      this.optionSlag = [];
      this.slag_id.map((item) => {
        let data = item.id;
        this.optionSlag.push(data);
      });
    },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters([
      "contentChapter",
      "contentPage",
      "listNote",
      "listSlag",
      "message",
      "success",
      "error",
      "noteById",
    ]),
  },
  methods: {
    ...mapActions({ getContentPage: "getContentPage" }),
    ...mapActions({ getContentChapter: "getContentChapter" }),
    ...mapActions({ getListNote: "getListNote" }),
    ...mapActions({ getListSlag: "getListSlag" }),
    ...mapActions({ createNote: "createNote" }),
    ...mapActions({ getNoteByIdUser: "getNoteByIdUser" }),
    ...mapActions({ updateNoteById: "updateNoteById" }),
    ...mapActions({ deleteNote: "deleteNote" }),
    toggleListChapter() {
      this.openListChapter = !this.openListChapter;
      this.openNote = false;
    },
    toggleNote() {
      this.openNote = !this.openNote;
      this.openListChapter = false;
    },
    pushPage(idContent, idPage) {
      if (idPage != this.idPage) {
        this.$router.push({
          name: this.$route.params.shopId
            ? "default page"
            : "default page domain",
          params: { idContent, idPage },
        });
      }
    },
    getIndex(item, index) {
      if (item.id == this.idPage) {
        this.indexPage = index;
      }
    },
    nextPage(var1, var2) {
      if (var1 !== var2) {
        this.$router.push({
          name: this.$route.params.shopId
            ? "default page"
            : "default page domain",
          params: {
            idContent: this.idContent,
            idPage: this.contentPage[var1 + 1].id,
          },
        });
      }
    },
    prevPage(indexPage) {
      if (indexPage !== 0) {
        this.$router.push({
          name: this.$route.params.shopId
            ? "default page"
            : "default page domain",
          params: {
            idContent: this.idContent,
            idPage: this.contentPage[indexPage - 1].id,
          },
        });
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    CreateNewNote() {
      if (this.title_note === "") {
        this.$toasted.error("ノートタイトルは入力必須項目です。");
      } else if (this.content_note == "") {
        this.$toasted.error("コンテンツノートは入力必須項目です。");
      } else {
        this.isLoading = true;
        const formData = {
          content_id: parseInt(this.idContent),
          page_id: parseInt(this.idPage),
          slag: this.optionSlag,
          title_note: this.title_note,
          content_note: this.content_note,
          page_pdf: parseInt(this.idPage),
          seconds_watch_video: this.seconds_watch_video,
        };
        this.createNote(formData);
        this.title_note = "";
        this.content_note = "";
        this.page_pdf = "";
        this.seconds_watch_video = "";
        this.slag_id = [];
        this.$nextTick(() => {
          this.$bvModal.hide("modal-create-note");
        });
        this.getListNote(this.idContent);
      }
    },
    editNote() {
      if (this.noteById.title_note === "") {
        this.$toasted.error("ノートタイトルは入力必須項目です。");
      } else if (this.noteById.content_note == "") {
        this.$toasted.error("コンテンツノートは入力必須項目です。");
      } else {
        this.isLoading = true;
        const formData = {
          id: this.noteById.id,
          content_id: this.noteById.content_id,
          page_id: this.noteById.page_id,
          slag: this.noteById.optionSlag,
          title_note: this.noteById.title_note,
          content_note: this.noteById.content_note,
          page_pdf: this.noteById.page_pdf,
          seconds_watch_video: this.noteById.seconds_watch_video,
        };
        this.updateNoteById(formData);
        this.$nextTick(() => {
          this.$bvModal.hide("modal-edit-note");
        });
        this.getListNote(this.idContent);
      }
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    removeNote(id) {
      this.deleteNote(id);
      this.$nextTick(() => {
        this.$bvModal.hide("modal-delete-note");
      });
    },
  },
};
</script>

<style lang="scss">
.page {
  .menu-content {
    top: 163px;
    bottom: 0;
    left: 0;
    box-shadow: 3px -3px 10px 0px #e3e3e3;
    background: #fff;
    .menu-content-items {
      width: 80px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      background: #fff;
      .menu-content-item {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        &:hover {
          background: #eee;
        }
        &.disable {
          cursor: unset;
          color: #c5c5c5;
          &:hover {
            background: #fff;
          }
        }
      }
      .menu-content-group-item {
        width: 100%;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        .menu-content-item {
          &.border-if {
            border-top: 1px solid #e5e5e5;
          }
        }
      }
      &.active {
        border-right: 1px solid #e5e5e5;
      }
    }
    .menu-toggle {
      position: absolute;
      top: 0;
      left: 100%;
      width: 500px;
      height: 100%;
      transition: all 0.5s;
      transform: translateX(calc(-100% - 80px));
      background: #fff;
      z-index: -1;
      box-shadow: 3px -3px 10px 0px #e3e3e3;
      &.active {
        transform: translateX(0%);
      }
    }
    .menu-title {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      border-bottom: 1px solid #e5e5e5;
    }
    .list-chapter {
      border-bottom: 1px solid #e5e5e5;
      .item-chapter {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        .custom-icon {
          font-size: 20px;
          color: #008000;
        }
        .chapter-name {
          font-size: 18px;
        }
        &.borderIf {
          border-top: 1px solid #e5e5e5;
        }
        &:hover {
          background-color: #f2f2f2;
        }
        &.active {
          background: #f2f2f2;
          cursor: unset;
        }
      }
    }
    .menu-toggle-header {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e5e5;
      .header-title-custom {
        font-size: 20px;
      }
      .header-btn-custom {
        display: flex;
        align-items: center;
        cursor: pointer;
        .icon-custom {
          width: 20px;
          height: 20px;
        }
      }
    }
    .list-note {
      overflow: scroll;
      height: 100%;
      .note-item {
        padding: 20px;
        border-bottom: 1px solid #e5e5e5;
        position: relative;
        cursor: pointer;
        .time-note {
          font-size: 16px;
          color: #817d7d;
        }
        .content-note {
          font-size: 16px;
          color: #817d7d;
        }
        .title-note {
          font-size: 18px;
          font-weight: 600;
        }
        .item-note {
          position: absolute;
          right: 0;
        }
      }
    }
  }
}
.modal-backdrop {
  background-color: #00000038;
}
</style>
